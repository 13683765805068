import React from 'react';


import PageBanner from '../../components/PageBanner'

import { Helmet } from 'react-helmet';
import GallerySection from '../../components/GallerySection';


function ContactPage() {

  const backgroundImage =   process.env.REACT_APP_CONTACT_BG_BACKGROUND;
    
  return (
    <>
     <Helmet>
    <title>Contact Barot Valley: Get in Touch Today</title>
    <meta name="description" content="Have questions about your visit to Barot Valley? Contact us for inquiries, bookings, or general information. Discover the serene beauty of this Himalayan paradise." />
    <meta name="keywords" content="Barot Valley,Contact,Lakes, Lapas Waterfall, Hidden gem,Travel" />
      </Helmet>

    <PageBanner pageTitle={"Contact Us"} backgroundImage={backgroundImage} />
      {/*====== Start Info Section ======*/}
      <section className="contact-info-section pt-100 pb-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              {/*=== Section Title ===*/}
              <div className="section-title text-center mb-45 wow fadeInDown">
                <span className="sub-title">Contact Us</span>
                <h2>
                  Ready to Get Our Best Services! Feel Free to Contact With Us
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/*=== Contact Info Item ===*/}
              <div className="contact-info-item text-center mb-40 wow fadeInUp">
                <div className="icon">
                  <img src="assets/images/icon/icon-1.png" alt="icon" />
                </div>
                <div className="info">
                  <span className="title">Location</span>
                  <p>Barot valley</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/*=== Contact Info Item ===*/}
              <div className="contact-info-item text-center mb-40 wow fadeInDown">
                <div className="icon">
                  <img src="assets/images/icon/icon-2.png" alt="icon" />
                </div>
                <div className="info">
                  <span className="title">Email Address</span>
                  <p>
                    <a href="mailto:support@barotvalley.com">
                    support@barotvalley.com
                    </a>
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/*=== Contact Info Item ===*/}
              <div className="contact-info-item text-center mb-40 wow fadeInUp">
                <div className="icon">
                  <img src="assets/images/icon/icon-3.png" alt="icon" />
                </div>
                <div className="info">
                  <span className="title">Hotline</span>
                  
                  <p>
                    <a href="tel:+917018079512">+917018079512</a>
                  </p>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </section>
      {/*====== End Info Section ======*/}
      <GallerySection/>
      
    </>
  )
}

export default ContactPage