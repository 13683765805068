
import Slider from "react-slick";
import Skeleton from '@mui/material/Skeleton';

function HomeSliderSketon() {
    function Arrow({ className, extraClass, onClick, icon }) {
        return (
          <div className={`${className}  ${extraClass}`} onClick={onClick}>
            <i className={icon}></i>
          </div>
        );
      }
  
       const home1Slider = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        fade: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <Arrow extraClass={"prev"} icon={"fal fa-arrow-left"} />,
        nextArrow: <Arrow extraClass={"next"} icon={"fal fa-arrow-right"} />,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
            },
          },
        ],
      };
  return (
   <>
    <section className="hero-section">
        {/*=== Hero Wrapper ===*/}
        <div className="hero-wrapper black-bg">
          {/*=== Hero Slider ===*/}
          <Slider {...home1Slider} className="hero-slider-one">
            {/*=== Single Slider ===*/}
            <div className="single-slider">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-xl-6">
                    {/*=== Hero Content ===*/}
                    <div className="hero-content text-white">
                      <h1 data-animation="fadeInDown" data-delay=".4s">
                      <Skeleton animation="wave" variant="rectangular" width={400} height={400}  sx={{ backgroundColor: '#464646' }}/>
                      </h1>
                      <div className="text-button d-flex align-items-center">
                        <p data-animation="fadeInLeft" data-delay=".5s">
                        <Skeleton animation="wave" variant="rectangular" width={80} height={50}  sx={{ backgroundColor: '#464646' }}/>
                        </p>
                        <div
                          className="hero-button"
                          data-animation="fadeInRight"
                          data-delay=".6s"
                        >
                          <a  href="/about">
                          
                            <Skeleton animation="wave" variant="circular" width={200} height={50} sx={{ backgroundColor: '#464646' }} />
                              
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    {/*=== Hero Image ===*/}
                    <div className="hero-image" data-animation="fadeInRight">
                    <Skeleton animation="wave" variant="rectangular" width={500} height={500}  sx={{ backgroundColor: '#464646' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </Slider>
        </div>
      </section>
   </>
  )
}

export default HomeSliderSketon