import React from 'react'
import { Helmet } from 'react-helmet'
import { Nav, Tab } from "react-bootstrap";
import PageBanner from '../../components/PageBanner'
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import GallerySection from '../../components/GallerySection';
function PrivacyPolicy() {

  const backgroundImage =   process.env.REACT_APP_BLOG_BG_PRODUCTION;

    return (
        <>
        <Helmet>
           <title>Privacy Policy -Barotvalley.com</title>
           <meta name="description" content="Read our Privacy Policy to understand how we collect, use, and protect your personal information. Your privacy is important to us, and we are committed to safeguarding your data. Learn more about our practices and your rights." />
           <meta name="keywords" content="Privacy Policy, data protection, personal information, user privacy, data security, information collection, privacy rights, data usage, privacy practices, Your Company Name" />
        </Helmet>
            <PageBanner pageTitle={"Privacy& Policy"}   backgroundImage={backgroundImage} />
            <section className="place-details-section">
        {/*=== Place Slider ===*/}
        
        <div className="container">
          {/*=== Tour Details Wrapper ===*/}
          <div className="tour-details-wrapper pt-80">
            
          
            <div className="row">
              <div className="col-xl-12">
                {/*=== Place Content Wrap ===*/}
                <div className="place-content-wrap pt-45 wow fadeInUp">
                  <h3 className="title">Effective Date: 10-09-2024</h3>
                  <p>
                  We operates the website barotvalley.com  This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
                  </p>
                  <h4>External Resources</h4>
                  <p>
                  Our website may contain links to external websites that are not operated by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any of these third-party websites.
                  </p>
                  
                  <h4>Information We Collect:</h4>
                  <p>
                  We do not use cookies or collect any personal information from users who visit our website. However, if you choose to connect with us on WhatsApp, you will be providing your phone number to an external platform (WhatsApp)
                  </p>
                  <h4 className="title"> Use of Information:</h4>
                    <p>
                    We do not use any of the information collected through our website for any purpose. If you choose to connect with us on WhatsApp, your phone number will be used solely for communication purposes via that platform.  
                    </p>
                    <h4 className="title"> Sharing of Information:</h4>
                    <p>
                    We do not share any personal information collected through our website with any third party. Your phone number, if provided via WhatsApp, will be used only for communication purposes with Barot Valley on that platform..  
                    </p>
                    <h4 className="title"> WhatsApp::</h4>
                    <p>
                    By clicking on a WhatsApp link on our website or initiating a WhatsApp chat, you acknowledge and agree that you are using an external platform and that your phone number will be subject to WhatsApp's privacy policy.  
                    </p>
                    <h4 className="title"> Changes to This Privacy Policy::</h4>
                    <p>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.  
                    </p>
                    <h4 className="title"> Contact Us::</h4>
                    <p>
                    If you have any questions about this Privacy Policy, please contact us: support@barotvalley.com  
                    </p>
                    <h4 className="title"> Disclaimer for External Images::</h4>
                    <p>
                    This website may contain images that are sourced from external websites /social media  We do not claim ownership of these images unless explicitly stated..
                    
                    The content and images used on this site are copyright protected and copyrights vests with the respective owners. The usage of the content and images on this website is intended to promote the works and no endorsement of the artist shall be implied. Unauthorized use is prohibited and punishable by law.  
                    </p>
                </div>
                
        
              </div>
              
            </div>
          </div>
        </div>
      </section>
           
        </>
  )
}

export default PrivacyPolicy