import React from 'react'
import HomeLayoutHeader from '../../components/Header/HomeLayoutHeader';
import Footer from '../../components/Footer/Footer';

import HomeSlider from '../../components/Slider/HomeSlider';

import { Outlet } from 'react-router-dom';
import { useEffect,useState } from "react";
import useWindowSize from "../../components/useWindowSize";
import PreLoader from '../../components/Preloader/Preloader';

import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
function HomeLayout() {

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  const { width } = useWindowSize();
  useEffect(() => {
    const headers = document.querySelectorAll(".header-navigation");
    headers.forEach((header) => {
      if (width <= 1199) {
        header.classList.add("breakpoint-on");
      } else {
        header.classList.remove("breakpoint-on");
      }
      // toggle
      const toggleBtn = header.getElementsByClassName("navbar-toggler")[0],
        overlay = header.getElementsByClassName("nav-overlay")[0],
        menu = header.getElementsByClassName("nav-menu")[0];
      toggleBtn.addEventListener("click", () => {
        overlay.classList.add("active");
        menu.classList.add("menu-on");
      });
      overlay.addEventListener("click", () => {
        overlay.classList.remove("active");
        menu.classList.remove("menu-on");
      });
    });
  }, [width]);


  return (
    <>
  
<HomeLayoutHeader />
<HomeSlider />
<Outlet/>
<WhatsAppWidget 
        phoneNumber="7018079512" 
        companyName="Barotvalley" 
        message="Hello! How can we help you today?" 
      />
  <Footer/>
    </>
  )
}

export default HomeLayout