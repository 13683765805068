import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Slider from 'react-slick';
function PlacesSkelton() {
    const sliderActive5Item = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };


  return (
    <>
  <Slider
            {...sliderActive5Item}
            className="slider-active-5-item wow fadeInUp"
          >
            {/*=== Features Item ===*/}
            <div className="single-features-item-three">
              <div className="img-holder">
              <Skeleton animation="wave" variant="rectangular" width={234} height={300} />
              </div>
             
            </div>
            {/*=== Features Item ===*/}
            <div className="single-features-item-three">
              <div className="img-holder">
              <Skeleton animation="wave" variant="rectangular" width={234} height={300} />
              </div>
              
            </div>
            {/*=== Features Item ===*/}
            <div className="single-features-item-three">
              <div className="img-holder">
              <Skeleton animation="wave" variant="rectangular" width={234} height={300} />
              </div>
              
            </div>
            {/*=== Features Item ===*/}
            <div className="single-features-item-three">
              <div className="img-holder">
              <Skeleton animation="wave" variant="rectangular" width={234} height={300} />
              </div>
              
            </div>
            {/*=== Features Item ===*/}
            <div className="single-features-item-three">
              <div className="img-holder">
              <Skeleton animation="wave" variant="rectangular" width={234} height={300} />
              </div>
              
            </div>
            {/*=== Features Item ===*/}
            <div className="single-features-item-three">
              <div className="img-holder">
              <Skeleton animation="wave" variant="rectangular" width={234} height={234} />
              </div>
             
            </div>
          </Slider>
  </>
  )
}

export default PlacesSkelton