

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';


import AccommodationSkelton from '../../AccommodationPage/AccommodationSkelton';

// Function to conditionally load the image URL based on environment
function getPlacesImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_PLACES_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_PLACES_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getPlacesapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_PLACES_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_PLACES_PRODUCTION; // Production URL
  }
}

function PlacesList() {

  const imageUrl = getPlacesImageUrl();

  const placesApiUrl = getPlacesapiUrl();


  function OpenWhatsAppChat() {
    const yourWhatsAppNumber = '7018079512'; // Replace with your actual number
    const encodedNumber = encodeURIComponent(yourWhatsAppNumber);
    const link = `https://wa.me/${encodedNumber}`; // Create a WhatsApp chat link
    window.location.href = link; // Redirect the user to the chat link
  }

    const [Places, setPlaces] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [displayedPlaces, setDisplayedPlaces] = useState([]);

    const environment = process.env.NODE_ENV;
    
    useEffect(() => {
      const fetchPlaces = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(placesApiUrl); // Replace with your API endpoint
          setTimeout(() => {
            setPlaces(response.data);
            setDisplayedPlaces(response.data.slice(0, 3)); // Display initial 3
            setIsLoading(false);
          }, 2000); // 2-second delay
        } catch (error) {
          setError(error.message);
          setIsLoading(false);
        }
      };
  
      fetchPlaces();
    }, []);


     // Function to load more images (optional)
  const handleLoadMore = () => {
    if (displayedPlaces.length < Places.length) {
      const newDisplayed = displayedPlaces.concat(
        Places.slice(displayedPlaces.length, displayedPlaces.length + 3)
      );
      setDisplayedPlaces(newDisplayed);
    }
  };


  return (
    <>
        
        <section className="products-section pt-100 pb-90">
        <div className="container">
          {/* ... Products Filter (if needed) ... */}

          <div className="row">
            {isLoading ? (
              // Display skeletons while loading
              <>
                <AccommodationSkelton/>
              </>
            ) : error ? (
              <div className="error-message">{error}</div>
            ) : (
              displayedPlaces.map((places, index) => (
                <div className="col-xl-4 col-md-6 col-sm-12" key={index}>
                  <div className="single-product-item mb-50 wow fadeInUp">
                    <div className="img-holder">
                      <img src={`${imageUrl}/${places.image}`} alt={places.name} />
                      <div className="tag">
                        <span className="off">Popular</span>
                      </div>
                      <div className="content-hover">
                        <Link to={`/places/${places.id}`} className="main-btn primary-btn">
                          places <i className="far fa-shopping-bag" />
                        </Link>
                      </div>
                    </div>
                    <div className="content">
                      <div className="info">
                        <h4 className="title">
                          <Link to={`/places/${places.id}`}>
                            {places.name}
                          </Link>
                        </h4>
                        {/* ... other accommodation details ... */}
                      </div>
                      <ul className="ratings">
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                  </ul>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {displayedPlaces.length < Places.length && (
            <button onClick={handleLoadMore} className="main-btn primary-btn">
              Load More
            </button>
          )}
        </div>
      </section>
    
    </>
  )
}

export default PlacesList