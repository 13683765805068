import React from 'react'

function Why() {
  return (
    <>
    {/*====== Start Why Choose Section ======*/}
    <section className="why-choose-section gray-bg pt-100 pb-50">
        <div className="container">
          <div className="row align-items-xl-center">
            <div className="col-xl-12">
              {/*=== Choose Content Box ===*/}
              <div className="choose-content-box pr-lg-70">
                {/*=== Section Title ===*/}
                <div className="section-title mb-45 wow fadeInDown">
                
                  <h2>Reason to Choose Barot Valley</h2>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-four mb-45 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-hiking" />
                      </div>
                      <div className="text">
                        <h4 className="title"> Adventure opportunities</h4>
                        <p>
                        The valley is a haven for adventure enthusiasts, with activities like trekking, camping, fishing, and birdwatching.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-four mb-45 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-journey" />
                      </div>
                      <div className="text">
                        <h4 className="title">Offbeat destination</h4>
                        <p>
                        Compared to more popular tourist destinations in Himachal Pradesh, Barot Valley offers a unique and less crowded experience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-four mb-45 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-solar-energy" />
                      </div>
                      <div className="text">
                        <h4 className="title"> Pleasant weather</h4>
                        <p>
                        Barot Valley enjoys a pleasant climate throughout the year, making it a suitable destination for a vacation any time of the year.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-four mb-45 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-caravan" />
                      </div>
                      <div className="text">
                        <h4 className="title">Peaceful atmosphere</h4>
                        <p>
                        The valley's tranquil ambiance and friendly locals create a relaxed and welcoming atmosphere for visitors.
                        </p>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      {/*====== End Why Choose Section ======*/}
    </>
  )
}

export default Why