import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>{/*====== Start Footer ======*/}
  <footer className="main-footer black-bg pt-230">
    <div className="container">
      {/*=== Footer Top ===*/}
      <div className="footer-top pt-40 wow fadeInUp">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            {/*=== Single Info Item ===*/}
            <div className="single-info-item mb-40">
              <div className="icon">
                <i className="far fa-map-marker-alt" />
              </div>
              <div className="info">
                <span className="title">Location</span>
                <p>Barot Valley (H.p)</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            {/*=== Single Info Item ===*/}
            <div className="single-info-item mb-40">
              <div className="icon">
                <i className="far fa-envelope-open" />
              </div>
              <div className="info">
                <span className="title">Email</span>
                <p><a href="mailto:support@barotvalley.com">support@barotvalley.com</a></p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            {/*=== Single Info Item ===*/}
            <div className="single-info-item mb-40">
              <div className="icon">
                <i className="far fa-map-marker-alt" />
              </div>
              <div className="info">
                <span className="title">Hotline</span>
                <p><a href="tel:+917018079512">+917018079512</a></p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            {/*=== Social Box ===*/}
            <div className="social-box mb-40 float-lg-end">
              <ul className="social-link">
                <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                <li><a href="#"><i className="fab fa-twitter" /></a></li>
                <li><a href="#"><i className="fab fa-instagram" /></a></li>
                <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*=== Footer Widget ===*/}
      <div className="footer-widget-area pt-75 pb-30">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            {/*=== Footer Widget ===*/}
            <div className="footer-widget about-company-widget mb-40 wow fadeInUp">
              <h4 className="widget-title">About</h4>
              <div className="footer-content">
                <p>Barot Valley a hidden pardise</p>
                <Link href="#" className="footer-logo"><img src="/assets/images/logo/logo-white.png" alt="Site Logo" /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            {/*=== Footer Widget ===*/}
            <div className="footer-widget service-nav-widget mb-40 pl-lg-70 wow fadeInDown">
              <h4 className="widget-title">Services</h4>
              <div className="footer-content">
                <ul className="footer-widget-nav">
                  <li><a href="https://himachalithub.com/">Website Development</a></li>
                  <li><a href="https://himachalithub.com/">App Development</a></li>
                  <li><a href="https://himachalithub.com/">Ui/Ux Design</a></li>
                  <li><Link to="/home">Home</Link></li>
                
                
                </ul>
                <ul className="footer-widget-nav">
                  <li><a href="Himachalithub.com">Need a Career ?</a></li>
                  <li><Link to="/blog">Latest News &amp; Blog</Link></li>
                  <li><Link to="/places">Places</Link></li>
                  <li><Link to="/gallery">Gallery</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            {/*=== Footer Widget ===*/}
            <div className="footer-widget footer-newsletter-widget mb-40 pl-lg-100 wow fadeInUp">
              <h4 className="widget-title">Newsletter</h4>
              <div className="footer-content">
                
                <form>
                  <div className="form_group">
                    <label><i className="far fa-paper-plane" /></label>
                    <input type="email" className="form_control" placeholder="Email Address" name="email" required />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=== Footer Copyright ===*/}
      <div className="footer-copyright">
        <div className="row">
          <div className="col-lg-6">
            {/*=== Footer Text ===*/}
            <div className="footer-text">
              <p>Developed & Managed by <span style={{color: '#F7921E'}}><a href="https://himachalithub.com/">Himachalithub.com</a></span></p>
            </div>
          </div>
          <div className="col-lg-6">
            {/*=== Footer Nav ===*/}
            <div className="footer-nav float-lg-end">
              <ul>
                <li><a href="#">Setting &amp; privacy</a></li>
                <li><a href="#">Faqs</a></li>
                <li><a href="#">Support</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>{/*====== End Footer ======*/}</div>

  )
}

export default Footer