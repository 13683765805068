import React from 'react'

import Skeleton from '@mui/material/Skeleton';
import Slider from "react-slick";
function AccommodationSkelton() {
    const sliderActive4Item = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
      return (
        <>{/*====== Start About Section ======*/}
        <section className="about-section pt-100">
          <div className="container-fluid">
           
            <Slider
              {...sliderActive4Item}
              className="slider-active-4-item wow fadeInUp"
            >
              {/*=== Features Image Item ===*/}
              <div className="single-features-item mb-40">
                <div className="img-holder">
                <Skeleton animation="wave" variant="rectangular" width={415} height={491} />
                  <div className="content">
                    <div className="text">
                      <h4 className="title"><Skeleton animation="wave" variant="rectangular" width={200} height={40} /></h4>
                      
                    </div>
                    <p><Skeleton animation="wave" variant="rectangular" width={200} height={40} /></p>
                  </div>
                </div>
              </div>
               {/*=== Features Image Item ===*/}
               <div className="single-features-item mb-40">
                <div className="img-holder">
                <Skeleton animation="wave" variant="rectangular" width={415} height={491} />
                  <div className="content">
                    <div className="text">
                      <h4 className="title"><Skeleton animation="wave" variant="rectangular" width={200} height={40} /></h4>
                      
                    </div>
                    <p><Skeleton animation="wave" variant="rectangular" width={200} height={40} /></p>
                  </div>
                </div>
              </div>
               {/*=== Features Image Item ===*/}
               <div className="single-features-item mb-40">
                <div className="img-holder">
                <Skeleton animation="wave" variant="rectangular" width={415} height={491} />
                  <div className="content">
                    <div className="text">
                      <h4 className="title"><Skeleton animation="wave" variant="rectangular" width={200} height={40} /></h4>
                      
                    </div>
                    <p><Skeleton animation="wave" variant="rectangular" width={200} height={40} /></p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        </>
      )
}

export default AccommodationSkelton