import React from 'react'

import Skeleton from '@mui/material/Skeleton';
import Slider from "react-slick";
function TestimonialSketon() {

    const sliderActive3ItemDot = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 800,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

  return (
    <>
  <Slider {...sliderActive3ItemDot} className="slider-active-3-item-dot">
            <div className="gw-testimonial-item-two">
              <div className="testimonial-inner-content">
                <div className="quote-rating-box">
                  <div className="icon">
                  <Skeleton animation="wave" variant="circular" width={100} height={100} />
                  </div>
                  <div className="ratings-box">
                    <h4><Skeleton animation="wave" variant="rectangular" width={200} height={40} />.</h4>
                    <ul className="ratings">
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                <Skeleton animation="wave" variant="rectangular" width={400} height={100} />.
                </p>
                <div className="author-thumb-title">
                  <div className="author-thumb">
                  <Skeleton animation="wave" variant="circular" width={80} height={80} />
                  </div>
                  <div className="author-title">
                    <h3 className="title">  <Skeleton animation="wave" variant="rectangular" width={300} height={40} /></h3>
                    <p className="position"><Skeleton animation="wave" variant="rectangular" width={300} height={40} /></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="gw-testimonial-item-two">
              <div className="testimonial-inner-content">
                <div className="quote-rating-box">
                  <div className="icon">
                  <Skeleton animation="wave" variant="circular" width={100} height={100} />
                  </div>
                  <div className="ratings-box">
                    <h4><Skeleton animation="wave" variant="rectangular" width={200} height={40} />.</h4>
                    <ul className="ratings">
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                <Skeleton animation="wave" variant="rectangular" width={400} height={100} />.
                </p>
                <div className="author-thumb-title">
                  <div className="author-thumb">
                  <Skeleton animation="wave" variant="circular" width={80} height={80} />
                  </div>
                  <div className="author-title">
                    <h3 className="title">  <Skeleton animation="wave" variant="rectangular" width={300} height={40} /></h3>
                    <p className="position"><Skeleton animation="wave" variant="rectangular" width={300} height={40} /></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="gw-testimonial-item-two">
              <div className="testimonial-inner-content">
                <div className="quote-rating-box">
                  <div className="icon">
                  <Skeleton animation="wave" variant="circular" width={100} height={100} />
                  </div>
                  <div className="ratings-box">
                    <h4><Skeleton animation="wave" variant="rectangular" width={200} height={40} />.</h4>
                    <ul className="ratings">
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                      <li>
                      <Skeleton animation="wave" variant="rectangular" width={20} height={20} />
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                <Skeleton animation="wave" variant="rectangular" width={400} height={100} />.
                </p>
                <div className="author-thumb-title">
                  <div className="author-thumb">
                  <Skeleton animation="wave" variant="circular" width={80} height={80} />
                  </div>
                  <div className="author-title">
                    <h3 className="title">  <Skeleton animation="wave" variant="rectangular" width={300} height={40} /></h3>
                    <p className="position"><Skeleton animation="wave" variant="rectangular" width={300} height={40} /></p>
                  </div>
                </div>
              </div>
            </div>
           
            
          </Slider>
       
      {/*====== End Testimonial Section ======*/}
    </>
  )
}

export default TestimonialSketon