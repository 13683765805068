import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

function MobileMenu() {
  const [activeMenu, setActiveMenu] = useState("");
  const activeMenuSet = (value) =>
    setActiveMenu(activeMenu === value ? "" : value);
  const activeLi = (value) =>
    value === activeMenu ? { display: "block" } : { display: "none" };

  return (
    <nav className="main-menu d-block d-xl-none">
      <ul>
        <li className="menu-item has-children">
          <Link to="/home">
            Home
            <span className="dd-trigger" onClick={() => activeMenuSet("home")}>
              <i className="far fa-angle-down" />
            </span>
          </Link>
        </li>
        <li className="menu-item has-children">
          <Link to="/accommodation">
            Accommodation
          </Link>
        </li>
        <li className="menu-item has-children">
          <Link to="/places">
            Places
            <span className="dd-trigger" onClick={() => activeMenuSet("places")}>
              <i className="far fa-angle-down" />
            </span>
          </Link>
        </li>
        <li className="menu-item has-children">
          <Link to="/blog">
            Blog
            <span className="dd-trigger" onClick={() => activeMenuSet("blog")}>
              <i className="far fa-angle-down" />
            </span>
          </Link>
        </li>
        <li className="menu-item has-children">
          <Link to="#">
            Pages
            <span className="dd-trigger" onClick={() => activeMenuSet("pages")}>
              <i className="far fa-angle-down" />
            </span>
          </Link>
          <ul className="sub-menu" style={activeLi("pages")}>
            <li>
              <Link to="about">About Us</Link>
            </li>
            <li>
              <Link to="gallery">Gallery</Link>
            </li>
            <li>
              <Link to="privacy">Privacy & Policy</Link>
            </li>
            <li>
              <Link to="contact">Contact</Link>
            </li>
          </ul>
        </li>
        
      </ul>
    </nav>
  );
}

export default MobileMenu;
