import React from 'react'

import Testimonials from '../../components/Testimonials/Testimonials'

import PageBanner from '../../components/PageBanner'

import { Helmet } from 'react-helmet';
import Why from '../../components/Why/Why';

function About() {
  const backgroundImage =   process.env.REACT_APP_ABOUT_US_BG_PRODUCTION;
  
  return (
   <>
   <Helmet>
       <title>Barot Valley: A Hidden Gem</title>
       <meta name="description" content="Discover the enchanting beauty of Barot Valley, a hidden gem nestled amidst the majestic Himalayas. Learn about its rich history, culture, and natural wonders. Immerse yourself in the tranquility of this serene destination and experience the true essence of Himalayan living." />
      <meta name="keywords" content="Barot Valley,Barot Valley attractions,Barot Valley tourist places,Barot Valley sightseeing,Barot Valley travel guide,Barot Valley hidden gems,Barot Valley adventure,Barot Valley tourism,Barot Valley nature,Himachal Pradesh, best place to visit in barotvalley,history of barot valley, is worth to visit in barot valley" />
    </Helmet>
      
    <PageBanner pageTitle={"About"}  backgroundImage={backgroundImage} />
   <section className="features-section pt-100 pb-50">
        <div className="container">
          <div className="row align-items-xl-center">
            <div className="col-xl-5">
              {/*=== Features Content Box ===*/}
              <div className="features-content-box pr-lg-70 mb-50 wow fadeInLeft">
                {/*=== Section Title ===*/}
                <div className="section-title mb-30">
                
                  <h3>About Barot Valley</h3>
                </div>
                <p className="mb-30">
                Barot Valley is a hidden gem nestled amidst the majestic Himalayas in Himachal Pradesh, India. Known for its serene beauty, pristine landscapes, and rich culture, Barot Valley offers a tranquil escape from the hustle and bustle of city life.
                </p>
               
              </div>
            </div>
            <div className="col-xl-7">
              <div className="features-item-area mb-20 pl-lg-70">
                <div className="row">
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-two mb-30 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-camping" />
                      </div>
                      <div className="text">
                        <h3 className="title">Tent Camping</h3>
                        <p>
                        Barot Valley offers a fantastic camping experience amidst the picturesque landscapes of the Himalayas.

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-two mb-30 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-biking-mountain" />
                      </div>
                      <div className="text">
                        <h3 className="title">Cycling </h3>
                        <p>
                        Barot Valley offers a fantastic cycling experience amidst the picturesque landscapes of the Himalayas.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-two mb-30 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-fishing-2" />
                      </div>
                      <div className="text">
                        <h3 className="title">Fishing</h3>
                        <p>
                        The Uhal River, flowing through the valley, is a popular spot for trout fishing.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-two mb-30 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-caravan" />
                      </div>
                      <div className="text">
                        <h3 className="title">Interacting with locals</h3>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== Start We Section ======*/}
      <section className="who-we-section">
        <div className="container">
          <div className="row align-items-xl-center">
            <div className="col-lg-6 order-2 order-lg-1">
              {/*=== We Image Box ===*/}
              <div className="we-image-box text-center text-lg-left wow fadeInDown">
                <img
                  src="/assets/images/gallery/we-1.jpg"
                  className="radius-top-left-right-288"
                  alt="Image"
                />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              {/*=== We Content Box ===*/}
              <div className="we-one_content-box">
                <div className="section-title mb-30 wow fadeInUp">
                  <span className="sub-title">History Barot valley</span>
                  
                </div>
                <p className="wow fadeInDown">
                <h3>Ancient Origins</h3><br/>


Barot Valley, nestled amidst the majestic Himalayas of Himachal Pradesh, India, has a history dating back to ancient times. Nomadic tribes are believed to have inhabited the region for centuries, leaving their mark on its cultural tapestry<br/>
</p>

<p className="wow fadeInDown">
<h3>British Era</h3><br></br>

During the British colonial era, Barot Valley gained prominence as a sought-after summer retreat. The serene beauty and pleasant climate of the valley attracted British officials and their families, leading to the establishment of plantations and other colonial infrastructure.

One significant development during this period was the construction of the Barot Hydroelectric Power Station. This project not only contributed to the region's economic growth but also left a lasting impact on its landscape.

</p>
<p className="wow fadeInDown">
<h3>Post-Independence</h3><br></br>

After India gained independence, Barot Valley continued to maintain its tranquil and secluded charm. While the region experienced a period of relative isolation, its natural beauty and cultural heritage remained intact.

</p>
<p className="wow fadeInDown">
<h3>Modern-Day Revival</h3><br></br>

In recent years, Barot Valley has witnessed a resurgence in popularity. Travelers seeking off-the-beaten-path experiences and a taste of authentic Himalayan culture have discovered this hidden gem. The valley's stunning landscapes, adventure opportunities, and warm hospitality have attracted visitors from around the world.

Today, Barot Valley offers a diverse range of experiences, from trekking through lush forests to exploring ancient temples and enjoying thrilling river rafting. Its rich history and natural beauty continue to captivate those who seek a peaceful and unforgettable getaway.

</p>





                <div className="skill-wrapper wow fadeInUp">
                  <div className="single-skill-circle text-center">
                    <div className="inner-circle">
                      <div className="line" />
                      <span className="number">90%</span>
                    </div>
                    <h5>Saticfied Clients</h5>
                  </div>
                  <div className="single-skill-circle text-center">
                    <div className="inner-circle">
                      <div className="line" />
                      <span className="number">93%</span>
                    </div>
                    <h5>Success Rate</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Why/>
       
      {/*====== End What We Section ======*/}
      <Testimonials/>
   </>
  )
}

export default About