import React from 'react'
import Skeleton from '@mui/material/Skeleton'; // Import Skeleton for loading effect
function AccommodationSkelton() {
  return (
    <>
               <div className="col-xl-4 col-md-6 col-sm-12">
                  <div className="single-product-item mb-50 wow fadeInUp">
                    <div className="img-holder">
                     
                      <Skeleton variant="rectangular" width={400} height={400} />
                     
                    </div>
                
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 col-sm-12">
                  <div className="single-product-item mb-50 wow fadeInUp">
                    <div className="img-holder">
                     
                      <Skeleton variant="rectangular" width={400} height={400} />
                     
                    </div>
                
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 col-sm-12">
                  <div className="single-product-item mb-50 wow fadeInUp">
                    <div className="img-holder">
                     
                      <Skeleton variant="rectangular" width={400} height={400} />
                    </div>
                  </div>
                </div>
    </>
  )
}

export default AccommodationSkelton