import React from 'react'
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import SearchModal from "../SearchModal";
function DeskTopMenu() {

    const [searchModal, setSearchModal] = useState(false);
  return (
    <Fragment>
        <SearchModal
        show={searchModal}
        handleClose={() => setSearchModal(false)}
      />
      <nav className="main-menu d-none d-xl-block">
        <ul>
          <li className="menu-item has-children">
            <Link to="/home">
              Home
            </Link>
          </li>
          <li className="menu-item has-children">
            <Link to="/accommodation">
              Accommodation
             
            </Link>
          </li>
          <li className="menu-item has-children">
            <Link to="/places">
              Places
            </Link>
          </li>
          <li className="menu-item has-children">
            <Link to="/blog">
              Blog
             
            </Link>
          
          </li>
          <li>
                <Link to="contact">Contact</Link>
              </li>
          <li className="menu-item has-children">
            <Link to="#">
              Pages
              <span className="dd-trigger">
                <i className="far fa-angle-down" />
              </span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="about">About Us</Link>
              </li>
              <li>
                <Link to="gallery">Our Gallery</Link>
              </li>
              <li>
                <Link to="privacy">Privacy& Policy</Link>
              </li>
            </ul>
          </li>
          <li className="menu-item search-item">
            <div
              className="search-btn"
              data-bs-toggle="modal"
              data-bs-target="#search-modal"
              onClick={() => setSearchModal(true)}
            >
              <i className="far fa-search" />
            </div>
          </li>
        </ul>
      </nav>
    </Fragment>
  )
}

export default DeskTopMenu