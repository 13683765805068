import React from 'react'

import { Link } from "react-router-dom";
import Menu from '../Menu';
import Skeleton from 'react-loading-skeleton';
function HomeLayoutHeader() {
  return (
    <header className="header-area header-one transparent-header">
    
      {/*====== Header Navigation ======*/}
      <div className="header-navigation navigation-white">
        <div className="nav-overlay" />
        <div className="container-fluid">
          <div className="primary-menu">
            {/*====== Site Branding ======*/}
            <div className="site-branding">
              <Link  to="/" smooth={true}>
                <a className="brand-logo">
                  <img
                    src="/assets/images/logo/logo-white.png"
                    alt="Site Logo"
                  />
                </a>
              </Link>
            </div>
            {/*====== Nav Menu ======*/}
            <div className="nav-menu">
              {/*====== Site Branding ======*/}
              <div className="mobile-logo mb-30 d-block d-xl-none">
                <Link  to="/">
                  <a className="brand-logo">
                    <img
                      src="/assets/images/logo/logo-black.png"
                      alt="Site Logo"
                    />
                  </a>
                
                </Link>
              </div>
              {/*=== Nav Search ===*/}
              
              {/*====== main Menu ======*/}
              <Menu />
              {/*====== Menu Button ======*/}
              <div className="menu-button mt-40 d-xl-none">
                <Link  to="/contact">
                  <a className="main-btn secondary-btn">
                    Book Now
                    <i className="fas fa-paper-plane" />
                  </a>
                </Link>
              </div>
            </div>
            {/*====== Nav Right Item ======*/}
            <div className="nav-right-item">
              <div className="menu-button d-xl-block d-none">
                <Link  to="/contact">
                  <a className="main-btn primary-btn">
                    Book Now
                    <i className="fas fa-paper-plane" />
                  </a>
                </Link>
              </div>
              <div className="navbar-toggler">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HomeLayoutHeader