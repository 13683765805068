import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route,Navigate } from 'react-router-dom';
import HomeLayout from '../layouts/HomeLayout/HomeLayout';
import OtherPagesLayout from '../layouts/PagesLayout/OtherPagesLayout';

import HomePage from '../pages/HomePage/HomePage';
import BlogsPage from '../pages/BlogsPage/BlogsPage.';
import GallerPage from '../pages/GalleryPage/GallerPage';
import AccommodationPage from '../pages/AccommodationPage/AccommodationPage';
import AccommodationDetails from '../pages/AccommodationDetails/AccommodationDetails';

import About from '../pages/About/About';

import ContactPage from '../pages/ContactPage/ContactPage';
import BlogsPageDetails from '../pages/BlogsPage/BlogsPageDetails';
import PlacesPage from '../pages/PlacesPage/PlacesPage';

import PlacesDetails from '../pages/PlacesPage/PlacesDetails';

import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
// Create a router instance

export const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/" element={<HomeLayout />}>
    <Route path="/"   element={<Navigate to="/home" replace />} />
      <Route path="home" element={<HomePage />} />
    </Route>,
    <Route path="*" element={<OtherPagesLayout />}>
      <Route path="blog" element={<BlogsPage />} />
      <Route path="places" element={<PlacesPage/>}/>
      <Route path="places/:placesId" element={<PlacesDetails />} />
      <Route path="gallery" element={<GallerPage />} />
      <Route path="accommodation" element={<AccommodationPage />} />
      <Route path="accommodation/:accommodationId" element={<AccommodationDetails />} />
      <Route path="blog/:blogId" element={<BlogsPageDetails />} />
      <Route path="contact" element={<ContactPage/>} />
      <Route path="about" element={<About/>} />
      <Route path="privacy" element={<PrivacyPolicy/>} />
    </Route>
  ])
);