import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { useParams, Link } from 'react-router-dom';
import PageBanner from '../../components/PageBanner';
import moment from 'moment-timezone';

import {ShareSocial} from 'react-share-social' 

import { Nav, Tab } from "react-bootstrap";

// Function to conditionally load the image URL based on environment
function getBlogImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_BLOGS_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_BLOGS_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getBlogapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_BLOG_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_BLOG_PRODUCTION; // Production URL
  }
}

function BlogDetails() {
  
  const imageUrl = getBlogImageUrl();

  const BlogApiUrl = getBlogapiUrl();

  const [blog, setBlog] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { blogId } = useParams();

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${BlogApiUrl}/${blogId}`);
        setBlog(response.data);

        
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (blogId) {
      fetchBlogDetails();
    }
  }, [blogId]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!blog) {
    return <p>Blog not found.</p>;
  }

  const words = blog.content.split(' ');
  const limitedWords = words.slice(0, 30);
  const limitedContent = limitedWords.join(' ');

  const sliderActive3Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  function displayIndianDate(isoString) {
    // Assuming your timezone is IST (India Standard Time)
    const indianTimeZone = 'Asia/Kolkata';
  
    // Convert ISO string to a moment object with the specified timezone
    const indianTime = moment.tz(isoString, indianTimeZone);
  
    // Format the date only
    const formattedDate = indianTime.format('YYYY-MM-DD');
  
    return formattedDate;
  }

  const isoString = blog.created_at;
const indianDate = displayIndianDate(isoString);

const backgroundImage =   process.env.REACT_APP_BLOG_BG_PRODUCTION;
  return (
    <>
      <PageBanner pageTitle={"Blog Details"}  backgroundImage={backgroundImage} />
      {/*====== Start Shop Details Section ======*/}
      <section className="shop-details-section pt-100 pb-50">
        <div className="container">
          <div className="product-details-wrapper">
            <div className="row align-items-xl-center">
              <div className="col-lg-6">
              <img  src={`${imageUrl}/${blog.featured_image}`}
                    className="rounded mb-40"
                    alt={blog.featured_image}
                  />
               
              </div>
              <div className="col-lg-6">
                <div className="product-info mb-50 pl-lg-70 wow fadeInRight">
                  <h4 className="title">{blog.title}</h4>
                  <ul className="ratings">
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    
                  </ul>
                 
                 
                  <p dangerouslySetInnerHTML={{ __html: limitedContent}} />
                  
                  

                  <ul className="social-link">
                    <li>
                      <span>Share</span>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
  
                
                  
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <Tab.Container defaultActiveKey={"descrptions"}>
                  <div className="description-wrapper mt-30 wow fadeInUp">
                    <div className="description-tabs mb-10">
                      <Nav as={"ul"} className="nav">
                        <Nav.Item as={"li"} className="nav-item">
                          <Nav.Link
                            as={"a"}
                            className="nav-link"
                            href="#descrptions"
                            eventKey="descrptions"
                          >
                            Description
                          </Nav.Link>
                        </Nav.Item>
                      
                      </Nav>
                    </div>
                    <Tab.Content className="tab-content wow fadeInUp">
                      <Tab.Pane
                        className="tab-pane fade"
                        eventKey="descrptions"
                      >
                        <div className="content-box">
                          
                          <p dangerouslySetInnerHTML={{ __html: blog.content }} />
             
                         
                        </div>
                      </Tab.Pane>
                      
                    </Tab.Content>
                   
                  </div>
                </Tab.Container>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      {/*====== End Shop Details Section ======*/}
      {/*====== Start Recent Product Section ======*/}
  
      
      {/*====== End Gallery Section ======*/}
    </>
  );
}

export default BlogDetails;