import React, { useEffect, useRef, memo } from 'react';

import Activity from '../../components/Activity/Activity';

import Why from '../../components/Why/Why';
import Testimonials from '../../components/Testimonials/Testimonials';

import Places from '../../components/Places/Places';
import Accommodation from '../../components/Accommodation/Accommodation';

import GallerySection from '../../components/GallerySection';
import { Helmet } from 'react-helmet';
import { animateScroll } from 'react-scroll';

const MemoizedPlaces = memo(Places);
const MemoizedActivity = memo(Activity);
const MemoizedWhy = memo(Why);
const MemoizedTestimonials = memo(Testimonials);
const MemoizedAccommodation = memo(Accommodation);
const MemoizedGallerySection = memo(GallerySection);



function HomePage() {

  return (
    <>
      <Helmet>
        <title>Discover Barot Valley: A Serene Himalayan Retreat</title>
        <meta name="description" content="Escape to the tranquil Barot Valley, nestled amidst the majestic Himalayas. Explore lush forests, cascading waterfalls, and serene lakes. Experience adventure, relaxation, and the beauty of nature in this hidden gem." />
        <meta name="keywords" content="Barot Valley, Himachal Pradesh, India, tourism, nature, adventure, vacation, relaxation, travel, top 10 places to visit in barotvalley" />
      </Helmet>

      <MemoizedPlaces />
      <MemoizedActivity />
      <MemoizedAccommodation />
      <MemoizedTestimonials />
      <MemoizedWhy />
      <MemoizedGallerySection />
    </>
  );
}

export default HomePage;