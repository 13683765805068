
import PageBanner from '../../components/PageBanner'

import Slider from 'react-slick'

import { useParams } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

// Function to conditionally load the image URL based on environment
function getAccommodationImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getAccommodationapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_PRODUCTION; // Production URL
  }
}

function AccommodationDetails() {

  const imageUrl = getAccommodationImageUrl();

  const AccommodationApiUrl = getAccommodationapiUrl();


  const [accommodation, setAccommodation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const backgroundImage =   process.env.REACT_APP_ACCOMMODATION_BG_BACKGROUND;

   
  const { accommodationId } = useParams();
  useEffect(() => {
    const fetchAccommodationDetails = async () => {

      const features = ['Family Camping', 'Wild Camping', 'Fishing', 'Mountain Biking', 'Free Wi-fi Internet', 'Transportation', 'Spa & GYM']; // Assuming you have a list of features
      
      
      try {
        setIsLoading(true);
        const response = await axios.get(`${AccommodationApiUrl}/${accommodationId}`); 
        // Replace with your API endpoint
        setAccommodation(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (accommodationId) {
      fetchAccommodationDetails(accommodationId);
    }
  }, [accommodationId]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!accommodation) {
    return <p>Accommodation not found.</p>;
  }


  const sliderActive3Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderActive3ItemDot = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>

<PageBanner pageTitle={"accommodations"}   backgroundImage={backgroundImage}/>
      {/*====== Start Destination Details Section ======*/}
      <section className="destination-details-section pt-100 pb-70">
        <div className="container">
          <div className="destination-details-wrapper">
            <div className="destination-info wow fadeInUp">
              <h3 className="title"> {accommodation.name}</h3>
              <div className="meta">
                <span className="location">
                  <i className="fas fa-map-marker-alt" />
                 Barot valley
                </span>
                <ul className="ratings">
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <i className="fas fa-star" />
                  </li>
                  <li>
                    <a href="#">(4.9)</a>
                  </li>
                </ul>
              </div>
              
              <div className="row">

              {accommodation.image.map((image, index) => (

                <div className="col-lg-6">
                   <img  src={`${imageUrl}/${image}`}
                    className="rounded mb-40"
                    alt={accommodation.name}
                  />


                </div>
              ))}
                
                {/************  end */}
               
              </div>
              <h3>Why Choose {accommodation.name}</h3>
            
              
               <p dangerouslySetInnerHTML={{ __html: accommodation.description }} />
              
              <ul className="features-list mb-40">
              {accommodation.amenities.map((amenities, index) => (
                <li>
                  <span>
                    <i className="fas fa-badge-check" />
                    {amenities}
                  </span>
                </li>
              ))}
              </ul>
            </div>
           
          </div>
        </div>
      </section>
      {/*====== End Destination Details Section ======*/}
      {/*====== Start CTA Section ======*/}
      <section
        className="cta-bg overlay bg_cover pt-150 pb-150"
        style={{ backgroundImage: "url(assets/images/bg/cta-bg2.jpg)" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-8">
              {/*=== CTA Content Box ===*/}
              <div className="cta-content-box text-white wow fadeInLeft">
                <h2 className="mb-35">
                  Ready to Travel With Real Adventure and Enjoy in Barot valley
                </h2>
                <Link legacyBehavior href="/about">
                  <a className="main-btn primary-btn">
                    Whats'app
                    <i className="far fa-paper-plane" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl-5 col-lg-4">
              {/*=== Play Box ===*/}
              <div className="play-box text-lg-end text-center wow fadeInRight">
                <a
                  href="https://www.youtube.com/watch?v=iewvgX5vSzc"
                  className="video-popup"
                >
                  <i className="fas fa-play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End CTA Section ======*/}
   
      
   
    </>
  )
}

export default AccommodationDetails