
import PageBanner from '../../components/PageBanner';
import PlacesPageSkelton from '../../components/SketonComponents/PlacesPageSkelton';

import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';


import PlacesList from './PlacesList/PlacesList';

function PlacesPage() {

  const backgroundImage =   process.env.REACT_APP_PLACES_BG_PRODUCTION;
  return (
    <>
    <Helmet>
       <title>Best places to visit in barotvalley</title>
      <meta name="description" content="Discover the best places to visit in Barot Valley, a hidden gem in the Himalayas. Explore stunning landscapes, experience thrilling adventures, and immerse yourself in the local culture. From serene lakes to breathtaking waterfalls, Barot Valley offers something for everyone." />
      <meta name="keywords" content="Barot Valley,Barot Valley attractions,Barot Valley tourist places,Barot Valley sightseeing,Barot Valley travel guide,Barot Valley hidden gems,Barot Valley adventure,Barot Valley tourism,Barot Valley nature,Himachal Pradesh, best place to visit in barotvalley" />
    </Helmet>
      
        <PageBanner pageTitle={"Places"} backgroundImage={backgroundImage}/>
            <PlacesList/>
    </>
  );
}

export default PlacesPage;