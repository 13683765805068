
import PageBanner from '../../components/PageBanner'

import { Link } from 'react-router-dom'

import Slider from 'react-slick'

import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import LazyLoad from 'react-lazyload';
import GallerySkelton from '../../components/SketonComponents/GallerySkelton';

import { Helmet } from 'react-helmet';

// Function to conditionally load the image URL based on environment
function getBlogImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_BLOGS_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_BLOGS_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getBlogapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_BLOG_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_BLOG_PRODUCTION; // Production URL
  }
}



function BlogsPage() {

  const imageUrl = getBlogImageUrl();

  const BlogApiUrl = getBlogapiUrl();

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const backgroundImage =   process.env.REACT_APP_BLOG_BG_PRODUCTION;
  
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(BlogApiUrl); // Replace with your API endpoint
          setBlogs(response.data);
          setIsLoading(false);

      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, []);




  const sliderActive3Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderContent = () => {
    if (isLoading) {
      return <GallerySkelton variant="blog-list" />; 
    }

    if (error) {
      return <p>Error loading blogs: {error.message}</p>;
    }

    return (
      <>
      <Helmet>
       <title>Best places to visit in barotvalley</title>
      <meta name="description" content="Discover the best places to visit in Barot Valley, a hidden gem in the Himalayas. Explore stunning landscapes, experience thrilling adventures, and immerse yourself in the local culture. From serene lakes to breathtaking waterfalls, Barot Valley offers something for everyone." />
      <meta name="keywords" content="Barot Valley,Barot Valley attractions,Barot Valley tourist places,Barot Valley sightseeing,Barot Valley travel guide,Barot Valley hidden gems,Barot Valley adventure,Barot Valley tourism,Barot Valley nature,Himachal Pradesh, best place to visit in barotvalley" />
    </Helmet>

      <Suspense fallback={<GallerySkelton variant="blog-list" />}>
       <section className="destination-section pb-100">
      <div className="container">
        <div className="row justify-content-center">
          {blogs.map((blog, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12">
               <Link to={`/blog/${blog.id}`}> {/* Link to specific accommodation route */}
                <div className="single-place-item-two mb-30 wow fadeInUp">
                  <div className="place-img">
                  <img  src={`${imageUrl}/${blog.featured_image}`}
                      alt={blog.title}
                    />
                    <div className="place-content">
                      <div className="info text-white">
                        <h6 className="title mb-10">{blog.title}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
      </Suspense>
      </>
      
    );
  };

 return (
    <>
      <PageBanner pageTitle={"Blogs"} backgroundImage={backgroundImage} />
      {/*====== Start Blog Section ======*/}
      <section className="blog-area pt-100 pb-70">
        <div className="container">{renderContent()}</div>
      </section>
      {/*====== End Blog Section ======*/}
    </>
  );
}

export default BlogsPage