import React from 'react'

function Activity() {
  return (
    <>{/*====== Start Features Section ======*/}
    <section className="features-section pt-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            {/*=== Section Title ===*/}
            <div className="section-title text-center mb-45 wow fadeInDown">
              <span className="sub-title">Popular Activities</span>
              <h2>Explore Real Activities</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6 col-sm-12">
            {/*=== Features Image Item ===*/}
            <div className="single-features-item-two mb-40 wow fadeInUp">
              <div className="img-holder">
                <img
                  src="assets/images/features/feat-5.jpg"
                  alt="Features Image"
                />
                <div className="item-overlay">
                  <div className="content">
                    <h3 className="title">Tent Camping</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12">
            {/*=== Features Image Item ===*/}
            <div className="single-features-item-two mb-40 wow fadeInDown">
              <div className="img-holder">
                <img
                  src="assets/images/features/feat-6.jpg"
                  alt="Features Image"
                />
                <div className="item-overlay">
                  <div className="content">
                    <h3 className="title">Cycling</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12">
            {/*=== Features Image Item ===*/}
            <div className="single-features-item-two mb-40 wow fadeInUp">
              <div className="img-holder">
                <img
                  src="assets/images/features/feat-7.jpg"
                  alt="Features Image"
                />
                <div className="item-overlay">
                  <div className="content">
                    <h3 className="title">Fishing</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12">
            {/*=== Features Image Item ===*/}
            <div className="single-features-item-two mb-40 wow fadeInDown">
              <div className="img-holder">
                <img
                  src="assets/images/features/feat-8.png"
                  alt="Features Image"
                />
                <div className="item-overlay">
                  <div className="content">
                    <h3 className="title">Explore  villages</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*====== End Features Section ======*/}
    </>
  )
}

export default Activity