
import { Fragment} from "react";
import MobileMenu from "./MobileMenu/MobileMenu";
import DeskTopMenu from "./DeskTopMenu/DeskTopMenu";

const Menu = () => {
  return (
    <Fragment>
      <DeskTopMenu />
      <MobileMenu />
    </Fragment>
  );
};
export default Menu;


