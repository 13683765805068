import Slider from "react-slick";

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Skeleton from '@mui/material/Skeleton';

import GallerySkelton from "./SketonComponents/GallerySkelton";
import BYDefaultGallery from "./ByDefaultGallery/BYDefaultGallery";


// Function to conditionally load the image URL based on environment
function getGalleryImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_GALLERY_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_GALLERY_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getGalleryapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_GALLERY_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_GALLERY_PRODUCTION; // Production URL
  }
}


const GallerySection = () => {

  const imageUrl = getGalleryImageUrl();

  const GalleryApiUrl = getGalleryapiUrl();



  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const defaultImages = [
    // Replace with your default image URLs
    "image1.jpg",
    "image2.jpg"
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(GalleryApiUrl);
  
        // Check if response data contains 'galleries' property
        if (response.data) {
          setGalleries(response.data);

          console.log(galleries);
        } else {
          console.error("API response doesn't contain 'galleries' property"); // Log an error message
        }
          setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);


   const sliderActive5Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (

    <>
    <section className="gallery-section mbm-150">
      <div className="container-fluid">
        {isLoading ? (
          <GallerySkelton/>
        ) : (
          <>
            <Slider {...sliderActive5Item} className="slider-active-5-item wow fadeInUp">
              {galleries.length > 0 ? (
                galleries.map((gallery) => (
                  <div key={gallery.id} className="single-gallery-item">
                    <div className="gallery-img">
                      <img  src={`${imageUrl}/${gallery.image}`} alt={gallery.alt_text} />
                      <div className="hover-overlay">
                        <a href={`${imageUrl}/${gallery.image}`} className="icon-btn img-popup">
                          <i className="far fa-plus" />
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              ) : null}
            </Slider>
            {/* Render default images if no galleries found */}
          </>
        )}
  
      </div>
    </section>
     
   </>
  );
};

export default GallerySection;
