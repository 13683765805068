import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton'; // Import Skeleton for loading effect

import PageBanner from '../../components/PageBanner';
import AccommodationSkelton from './AccommodationSkelton';

// Function to conditionally load the image URL based on environment
function getAccommodationImageUrl() {
  if (process.env.NODE_ENV === 'development') {

    
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getAccommodationapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_PRODUCTION; // Production URL
  }
}

function AccommodationPage() {




  const backgroundImage =   process.env.REACT_APP_ACCOMMODATION_BG_BACKGROUND;

  const imageUrl = getAccommodationImageUrl();
  const AccommodationApiUrl = getAccommodationapiUrl();

  const [accommodations, setAccommodations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayedAccommodations, setDisplayedAccommodations] = useState([]);

  useEffect(() => {
    const fetchAccommodations = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(AccommodationApiUrl);
       
          setAccommodations(response.data);
          setDisplayedAccommodations(response.data.slice(0, 3)); // Display initial 3
          setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchAccommodations();
  }, []);


  // Function to load more images (optional)
  const handleLoadMore = () => {
    if (displayedAccommodations.length < accommodations.length) {
      const newDisplayed = displayedAccommodations.concat(
        accommodations.slice(displayedAccommodations.length, displayedAccommodations.length + 3)
      );
      setDisplayedAccommodations(newDisplayed);
    }
  };

  return (
    <>
      <Helmet>
        <title>Discover Barot Valley: A Serene Himalayan Retreat</title>
        <meta name="description" content="Discover comfortable and affordable accommodations in Barot Valley, a serene Himalayan retreat. Explore a range of options, from cozy cottages to luxurious retreats. Enjoy the breathtaking beauty of the Himalayas while staying in our comfortable and well-equipped accommodations."/>
      <meta name="keywords" content="Barot Valley, accommodation, hotels, resorts, cottages, Himalayan retreat, nature, adventure, travel,best hotel in barot valley" />
      </Helmet>

      <PageBanner pageTitle={"Accommodation"}   backgroundImage={backgroundImage}  />
      <section className="products-section pt-100 pb-90">
        <div className="container">
          {/* ... Products Filter (if needed) ... */}

          <div className="row">
            {isLoading ? (
              // Display skeletons while loading
              <>
                <AccommodationSkelton/>
              </>
            ) : error ? (
              <div className="error-message">{error}</div>
            ) : (
              displayedAccommodations.map((accommodation, index) => (
                <div className="col-xl-4 col-md-6 col-sm-12" key={index}>
                  <div className="single-product-item mb-50 wow fadeInUp">
                    <div className="img-holder">
                      <img src={`${imageUrl}/${accommodation.image[0]}`} alt={accommodation.name} />
                      <div className="tag">
                        <span className="off">Popular</span>
                      </div>
                      <div className="content-hover">
                        <Link to={`/accommodation/${accommodation.id}`} className="main-btn primary-btn">
                          Accommodation <i className="far fa-shopping-bag" />
                        </Link>
                      </div>
                    </div>
                    <div className="content">
                      <div className="info">
                        <h4 className="title">
                          <Link to={`/accommodation/${accommodation.id}`}>
                            {accommodation.name}
                          </Link>
                        </h4>
                        {/* ... other accommodation details ... */}
                      </div>
                      <ul className="ratings">
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                  </ul>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {displayedAccommodations.length < accommodations.length && (
            <button onClick={handleLoadMore} className="main-btn primary-btn">
              Load More
            </button>
            
          )}
        </div>
      </section>
    </>
  );
}

export default AccommodationPage;