import React from 'react';
import Footer from '../../components/Footer/Footer';
import { Outlet, Link } from 'react-router-dom';
import useWindowSize from "../../components/useWindowSize";
import OtherLayoutHeader from '../../components/Header/OtherLayoutHeader';
import { useEffect,useState  } from "react";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import PreLoader from '../../components/Preloader/Preloader';
function OtherPagesLayout() {

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  const { width } = useWindowSize();
  useEffect(() => {
    const headers = document.querySelectorAll(".header-navigation");
    headers.forEach((header) => {
      if (width <= 1199) {
        header.classList.add("breakpoint-on");
      } else {
        header.classList.remove("breakpoint-on");
      }
      // toggle
      const toggleBtn = header.getElementsByClassName("navbar-toggler")[0],
        overlay = header.getElementsByClassName("nav-overlay")[0],
        menu = header.getElementsByClassName("nav-menu")[0];
      toggleBtn.addEventListener("click", () => {
        overlay.classList.add("active");
        menu.classList.add("menu-on");
      });
      overlay.addEventListener("click", () => {
        overlay.classList.remove("active");
        menu.classList.remove("menu-on");
      });
    });
  }, [width]);

  return (
    
    <>
    
    <OtherLayoutHeader/>
        {/* Main Content */}
        <main>
          <Outlet />
        </main>
        <WhatsAppWidget 
        phoneNumber="7018079512" 
        companyName="Barotvalley" 
        message="Hello! How can we help you today?" 
      />

        {/* Footer */}
        <Footer/>
      
    </>
  );
}

export default OtherPagesLayout;